const DANTHERM = 'DANTHERM'
const INGENIUM = 'INGENIUM'
const DANTHERM_INGENIUM = 'DANTHERM_INGENIUM'
const PKOM = 'PKOM'
const ZSAINDARI = 'ZSAINDARI'
const SLIM = 'SLIM'
const SLIM_INGENIUM = 'SLIM_INGENIUM'

export default {
  DANTHERM,
  INGENIUM,
  DANTHERM_INGENIUM,
  PKOM,
  ZSAINDARI,
  SLIM,
  SLIM_INGENIUM
}
