import DownloadSettings from './DownloadSettings'

import AddSystemModal from './AddSystemModal'
import EditSystemModal from './EditSystemModal'

import EditSystemLights from './EditSystemLights'
import EditSystemPeriodicity from './EditSystemPeriodicity'
import EditSystemEnvironment from './EditSystemEnvironment'

import TypeInfoModal from './TypeInfoModal'
import WhatIsNewModal from './WhatIsNewModal'
import ConfirmationModal from './ConfirmationModal'

import ProgramSelectedModal from './ProgramSelectedModal'
import ProgramsModal from './ProgramsModal'

import BypassInfoModal from './BypassInfoModal'
import FilterStatusInfoModal from './FilterStatusInfoModal'

import EditHolidayTempModal from './EditHolidayTempModal'
import EditTempPkomModal from './EditTempPkomModal'

import EditAcsPkomModal from './EditAcsPkomModal'
import MasterSystemModalContainer from './MasterSystemModal'
import returnDateModal from './returnDateModal'
import CondensationRiskModal from './CondensationRiskModal'

export default {
  CondensationRiskModal,
  ConfirmationModal,
  DownloadSettings,
  AddSystemModal,
  EditSystemModal,
  EditSystemLights,
  EditSystemPeriodicity,
  EditSystemEnvironment,
  TypeInfoModal,
  WhatIsNewModal,
  ProgramSelectedModal,
  ProgramsModal,
  BypassInfoModal,
  FilterStatusInfoModal,
  EditHolidayTempModal,
  EditTempPkomModal,
  EditAcsPkomModal,
  MasterSystemModalContainer,
  returnDateModal
}
