import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'

import { StylesProvider } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'

import Select from 'common/components/Select'
import styles from './EditSystemEnvironmentForm.module.scss'
import ZSaindariModels from 'constants/ZSaindariModels'

const EditSystemEnvironmentForm = props => {
  const { isSubmitting, initialValues, translate, onSubmit, submitError } = props
  const { model } = initialValues

  const { handleSubmit, register, errors, setValue, control } = useForm()

  const onClickCancel = () => {
    props.hideModal()
  }

  const error = () => {
    let message = translate('submitErrors.unknown')

    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
        style={{ visibility: submitError ? 'visible' : 'hidden' }}
      >
        {message}
      </Alert>
    )
  }

  const models = [
    {
      label: translate(ZSaindariModels.DANTHERM),
      value: ZSaindariModels.DANTHERM
    },
    {
      label: translate(ZSaindariModels.INGENIUM),
      value: ZSaindariModels.INGENIUM
    },
    {
      label: translate(ZSaindariModels.DANTHERM_INGENIUM),
      value: ZSaindariModels.DANTHERM_INGENIUM
    },
    {
      label: translate(ZSaindariModels.PKOM),
      value: ZSaindariModels.PKOM
    },
    {
      label: translate(ZSaindariModels.ZSAINDARI),
      value: ZSaindariModels.ZSAINDARI
    },
    {
      label: translate(ZSaindariModels.SLIM),
      value: ZSaindariModels.SLIM
    },
    {
      label: translate(ZSaindariModels.SLIM_INGENIUM),
      value: ZSaindariModels.SLIM_INGENIUM
    }
  ]

  const modelOptions = models.map(m => ({
    label: m.label,
    value: m.value
  }))

  let initialModel
  if (model) {
    initialModel = models.find(m => m.value === model)
    initialModel = { label: initialModel.label, value: initialModel.value }
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('editDeviceEnvironment')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.secondPage}>
              <div className={styles.cselect}>
                <Controller
                  id='environment'
                  name='environment'
                  className={styles.wrapperSelect}
                  render={({ onChange, name }) => (
                    <div className={styles.custom}>
                      <Select
                        required
                        onChange={e => {
                          setValue(name, e.value)
                        }}
                        autoComplete='shipping environment'
                        options={modelOptions}
                        value={model}
                        defaultValue={initialModel}
                        label={translate('environment')}
                        placeholder={translate('environment')}
                        error={errors.environment}
                        inputRef={register({ required: true })}
                        className={cx(styles.selector, {
                          [styles.hasError]: errors.environment
                        })}
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue={initialModel.value}
                  inputRef={register({ required: true })}
                  rules={{ required: true }}
                />
              </div>
              {error()}
              <div className={styles.buttons}>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting || errors.timeInterval}
                    loading={isSubmitting}
                    isLoading={isSubmitting}
                    loadingPosition='end'
                  >
                    {translate('applyChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    disableRipple
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditSystemEnvironmentForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(EditSystemEnvironmentForm)
