import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Modal } from '@material-ui/core'

import { getUser } from 'common/selectors/user'
import { hideModal } from 'common/actions/modal'
import { updateSystemEnvironment } from 'common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'
import { resetError } from 'common/actions/error'
import EditSystemEnvironmentForm from '../components/EditSystemEnvironmentForm/EditSystemEnvironmentForm'
import styles from './EditSystemEnvironmentContainer.module.scss'

const EditSystemEnvironmentContainer = props => {
  const [open] = useState(true)

  const { hideModal, system, updateSystemEnvironment, resetError, user } = props

  const handleSubmit = async data => {
    if (user.toJS().email !== 'demo@orkli.es') {
      hideModal()
      return
    }
    updateSystemEnvironment({
      _id: system._id,
      model: data.environment
    })
  }

  const initialValues = {
    model: system.model
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <EditSystemEnvironmentForm
          {...props}
          initialValues={initialValues}
          hideModal={hideModal}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateSystemEnvironment,
  hideModal,
  resetError
}

EditSystemEnvironmentContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(EditSystemEnvironmentContainer)
