import request from 'utils/request'

export function getSystemByIdWithDateRange(id, startDate, endDate) {
  return request(`systems/${id}/${startDate}/${endDate}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getSystemMeasurementsByTypeNew(data) {
  return request(`systems/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function getSystems(id) {
  return request('systems/byUserId', {
    method: 'POST',
    data: {
      id
    }
  }).then(([body]) => body)
}

export function getSystemsHome(id) {
  return request('systems/byUserId', {
    method: 'POST',
    data: {
      id
    }
  }).then(([body]) => body)
}

export function updateSystem(data) {
  return request('systems', {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function updateSystemLights(id, data) {
  return request(`systems/${id}/lights`, {
    method: 'PUT',
    data
  })
}

export function updateSystemPeriodicity(id, data) {
  return request(`systems/${id}/periodicity`, {
    method: 'PUT',
    data
  })
}

export function updateMultipleSystemLights(data) {
  return request(`systems/lights`, {
    method: 'PUT',
    data
  })
}

export function updateMultipleSystemPeriodicity(data) {
  return request(`systems/periodicity`, {
    method: 'PUT',
    data
  })
}

export function updateActiveProgram(id, data) {
  return request(`systems/${id}/activeProgram`, {
    method: 'PUT',
    data
  })
}

export function updateBypassMode(id, data) {
  return request(`systems/${id}/bypassMode`, {
    method: 'PUT',
    data
  })
}
export function updateBypassOn(id, data) {
  return request(`systems/${id}/bypassOn`, {
    method: 'PUT',
    data
  })
}

export function updateBoostOn(id, data) {
  return request(`systems/${id}/boostOn`, {
    method: 'PUT',
    data
  })
}

export function updateFanSpeed(id, data) {
  return request(`systems/${id}/fanSpeed`, {
    method: 'PUT',
    data
  })
}

export function updateFilterTime(id, data) {
  return request(`systems/${id}/filterTime`, {
    method: 'PUT',
    data
  })
}

export function updateWorkingMode(id, data) {
  return request(`systems/${id}/mode`, {
    method: 'PUT',
    data
  })
}

export function updateIngeniumMode(id, data) {
  return request(`systems/${id}/ingenium-mode`, {
    method: 'PUT',
    data
  })
}

export function updateIngeniumZones(id, data) {
  return request(`systems/${id}/ingenium-zones`, {
    method: 'PUT',
    data
  })
}

export function updatePkomMode(id, data) {
  return request(`systems/${id}/pkom-mode`, {
    method: 'PUT',
    data
  })
}

export function updatePkomClimaConsign(id, data) {
  return request(`systems/${id}/pkom-clima`, {
    method: 'PUT',
    data
  })
}

export function updatePkomClimaVacation(id, data) {
  return request(`systems/${id}/pkom-clima-vacation`, {
    method: 'PUT',
    data
  })
}

export function updatePkomACS(id, data) {
  return request(`systems/${id}/pkom-acs`, {
    method: 'PUT',
    data
  })
}

export function updatePkomVentilation(id, data) {
  return request(`systems/${id}/pkom-ventilation`, {
    method: 'PUT',
    data
  })
}

export function deleteDataFromUser(id) {
  return request(`systems/${id}/data`, {
    method: 'DELETE'
  })
}
