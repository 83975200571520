import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { store } from 'index'
import { injectReducer } from 'common/reducers'
import { injectSaga } from 'common/sagas'
import { getModalType, getModalProps } from 'common/selectors/modal'
import { hideModal } from 'common/actions/modal'

import * as ModalTypes from 'constants/ModalTypes'

import AddSystemModalReducer from 'modules/modals/AddSystemModal/reducers'
import EditSystemLigthsReducer from 'modules/modals/EditSystemLights/reducers'
import EditSystemPeriodicityReducer from 'modules/modals/EditSystemPeriodicity/reducers'
import EditSystemEnvironmentReducer from 'modules/modals/EditSystemEnvironment/reducers'

import Modals from 'modules/modals'

const MODAL_COMPONENT = {
  [ModalTypes.CONFIRMATION_MODAL]: Modals.ConfirmationModal,
  [ModalTypes.DOWNLOAD_SETTINGS]: Modals.DownloadSettings,
  [ModalTypes.ADD_SYSTEM]: Modals.AddSystemModal,
  [ModalTypes.EDIT_SYSTEM]: Modals.EditSystemModal,
  [ModalTypes.EDIT_LIGHTS]: Modals.EditSystemLights,
  [ModalTypes.EDIT_PERIODICITY]: Modals.EditSystemPeriodicity,
  [ModalTypes.TYPE_INFO]: Modals.TypeInfoModal,
  [ModalTypes.WHATS_NEW]: Modals.WhatIsNewModal,
  [ModalTypes.PROGRAM_SELECTED_MODAL]: Modals.ProgramSelectedModal,
  [ModalTypes.PROGRAMS_MODAL]: Modals.ProgramsModal,
  [ModalTypes.BYPASS_INFO]: Modals.BypassInfoModal,
  [ModalTypes.FILTER_STATUS]: Modals.FilterStatusInfoModal,
  [ModalTypes.EDIT_ENVIRONMENT]: Modals.EditSystemEnvironment,
  [ModalTypes.EDIT_HOLIDAY_TEMP_MODAL]: Modals.EditHolidayTempModal,
  [ModalTypes.EDIT_TEMP_PKOM_MODAL]: Modals.EditTempPkomModal,
  [ModalTypes.EDIT_ACS_PKOM_MODAL]: Modals.EditAcsPkomModal,
  [ModalTypes.MASTER_SYSTEM_MODAL_CONTAINER]: Modals.MasterSystemModalContainer,
  [ModalTypes.RETURN_DATE_MODAL]: Modals.returnDateModal,
  [ModalTypes.CONDENSATION_RISK_MODAL_CONTAINER]: Modals.CondensationRiskModal
}

const MODAL_REDUCERS = {
  [ModalTypes.ADD_SYSTEM]: AddSystemModalReducer,
  [ModalTypes.EDIT_LIGHTS]: EditSystemLigthsReducer,
  [ModalTypes.EDIT_PERIODICITY]: EditSystemPeriodicityReducer,
  [ModalTypes.EDIT_ENVIRONMENT]: EditSystemEnvironmentReducer
}

const MODAL_SAGAS = {
  // Empty
}

const typeToKey = type => {
  let key = type.toLowerCase()

  if (key.indexOf('_') !== -1) {
    const indexUnderScore = key.indexOf('_')
    const firstCharAfterUnderscore = key.charAt(key.indexOf('_') + 1)

    key = key.replace('_', '')
    key =
      key.substr(0, indexUnderScore) +
      firstCharAfterUnderscore.toUpperCase() +
      key.substr(indexUnderScore + 1)
  }
  return key
}

export class ModalRoot extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    props: PropTypes.object,
    hideModal: PropTypes.func.isRequired
  }

  render() {
    if (!this.props.type) return null

    const { type, props, hideModal } = this.props
    const ModalComponent = MODAL_COMPONENT[type]
    const reducer = MODAL_REDUCERS[type]
    const sagas = MODAL_SAGAS[type]

    if (reducer) {
      injectReducer(store, { key: typeToKey(type), reducer })
    }

    if (sagas) {
      injectSaga(store, { key: typeToKey(type), saga: sagas })
    }

    return (
      <ModalComponent
        {...props}
        hideModal={hideModal}
      />
    )
  }
}

const mapStateToProps = state => ({
  type: getModalType(state),
  props: getModalProps(state)
})

const mapActionsToProps = {
  hideModal
}

export default connect(mapStateToProps, mapActionsToProps)(ModalRoot)
