import * as storage from './storage'

export const setHomeActiveTabData = activeTab => {
  storage.setItem('homeActiveTab', activeTab)
}

export const setHomeSortDevicesOption = option => {
  storage.setItem('sortDevicesOption', option)
}

export const setUserMeasurementSystem = measurementSystem => {
  storage.setItem('measurementSystem', measurementSystem)
}

export const setControlPanelActiveTabData = activeTab => {
  storage.setItem('controlPanelActiveTab', activeTab)
}

export const getHomeActiveTabData = () => {
  const data = storage.getItem('homeActiveTab')

  if (!data) {
    return false
  }

  return data
}

export const getHomeSortDeviceOption = () => {
  const data = storage.getItem('sortDevicesOption')

  if (!data) {
    return false
  }

  return data
}

export const getUserMeasurementSystem = () => {
  const data = storage.getItem('measurementSystem')

  if (!data) {
    return 'mS'
  }

  return data
}

export const getControlPanelActiveTabData = () => {
  const data = storage.getItem('controlPanelActiveTab')

  if (data === null) {
    return false
  }

  return data
}
