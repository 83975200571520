import { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Carousel from 'react-material-ui-carousel'

// Material-UI imports
import { Button, Modal, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Imports from own project
import styles from './WhatIsNewModalContainer.module.scss'
import { LogoLightIcon, IndividualView, NewCharts } from 'icons'

const WhatIsNewModal = props => {
  const { hideModal, translate, onConfirmNews } = props

  const [open] = useState(true)
  const [showCarousel, setShowCarousel] = useState(false)

  let items = [
    {
      name: translate('whatIsNew.individualView'),
      image: IndividualView
    },
    {
      name: translate('whatIsNew.individualView'),
      description: (
        <Translate
          id={'whatIsNew.individualViewText'}
          options={{ renderInnerHtml: true }}
        />
      )
    },
    {
      name: translate('whatIsNew.newCharts'),
      image: NewCharts
    },
    {
      name: translate('whatIsNew.newCharts'),
      description: (
        <Translate
          id={'whatIsNew.newChartsText'}
          options={{ renderInnerHtml: true }}
        />
      )
    },
    {
      name: translate('whatIsNew.otherChanges'),
      description: (
        <Translate
          id={'whatIsNew.otherChangesText'}
          options={{ renderInnerHtml: true }}
        />
      )
    },
    {
      name: translate('whatIsNew.otherChangesBusiness'),
      description: (
        <Translate
          id={'whatIsNew.otherChangesBusinessText'}
          options={{ renderInnerHtml: true }}
        />
      ),
      last: true
    }
  ]

  const Item = props => {
    const { item } = props
    return (
      <Paper className={styles.pageWrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{item.name}</h1>
        </div>
        {item.image && (
          <div className={styles.imageWrapper}>
            <img
              src={item.image}
              className={styles.carouselImage}
              alt='carouselImage'
            />
          </div>
        )}
        {item.description && <div className={styles.descriptionWrapper}>{item.description}</div>}
        {item.last && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.understoodButton}
              onClick={handleUnderstood}
            >
              {translate('whatIsNew.understood')}
            </Button>
          </div>
        )}
      </Paper>
    )
  }

  const handleExplore = () => {
    setShowCarousel(true)
  }

  const handleUnderstood = () => {
    onConfirmNews()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.modalContainer}>
          {!showCarousel && (
            <div className={styles.mainPageWrapper}>
              <div className={styles.titleWrapper}>
                <img
                  className={styles.logoImage}
                  src={LogoLightIcon}
                  alt=''
                />
              </div>
              <h1 className={styles.title}>{translate('whatIsNew.newFunctions')}</h1>
              <Typography className={styles.mainDescription}>
                {translate('whatIsNew.discoverWhatIsNew')}
              </Typography>
              <div>
                <Button
                  className={styles.exploreButton}
                  onClick={handleExplore}
                >
                  {translate('whatIsNew.explore')}
                </Button>
              </div>
              <div>
                <Button
                  className={styles.exitButton}
                  onClick={hideModal}
                >
                  {translate('whatIsNew.remindMeLater')}
                </Button>
              </div>
            </div>
          )}
          {showCarousel && (
            <Carousel
              autoPlay={false}
              navButtonsProps={styles.carouselArrowButtons}
              navButtonsAlwaysVisible
            >
              {items.map((item, i) => (
                <Item
                  key={i}
                  item={item}
                />
              ))}
            </Carousel>
          )}
        </div>
      </Modal>
    </div>
  )
}

WhatIsNewModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default WhatIsNewModal
