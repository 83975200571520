import { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import EditSystemModalForm from '../components/EditSystemModalForm/EditSystemModalForm'
import { getUser } from 'common/selectors/user'
import { hideModal } from '../../../../common/actions/modal'
import { updateSystem } from '../../../../common/actions/systems'

const EditSystemContainer = props => {
  const [open] = useState(true)

  const { hideModal, system, updateSystem } = props

  const handleSubmit = async data => {
    delete data.macAddress
    data._id = system._id
    updateSystem(data)
    hideModal()
  }

  const initialValues = {
    _id: system._id,
    macAddress: system.macAddress,
    building: system.building,
    name: system.name,
    description: system.description,
    country: system.country,
    zipCode: system.zipCode,
    isPublic: system.isPublic
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <EditSystemModalForm
          initialValues={initialValues}
          hideModal={hideModal}
          onSubmit={handleSubmit}
        />
      </Modal>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state)
})

const mapActionsToProps = {
  updateSystem,
  hideModal
}

EditSystemContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(EditSystemContainer)
