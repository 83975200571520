import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Switch, Checkbox, FormControlLabel } from '@material-ui/core'

import styles from './EditSystemLightsForm.module.scss'
import { withLocalize } from 'react-localize-redux'
import { blue } from '@material-ui/core/colors'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import cx from 'classnames'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'

const useStyles = makeStyles(() => ({
  switch: {
    '& .Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'rgba(0,155,229,1)',
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-3px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  }
}))

const EditSystemLightsForm = props => {
  const classes = useStyles()

  const [checkedLights, setCheckedLights] = useState(false)
  const [allDeviceSelected, setAllDevicesSelected] = useState(false)

  const { isSubmitting, initialValues, translate, onSubmit, submitError } = props

  const { handleSubmit, register } = useForm()

  const handleChangeLightsSwitch = event => {
    setCheckedLights(event.target.checked)
  }

  const handleSelectAllDevices = event => {
    setAllDevicesSelected(event.target.checked)
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const error = () => {
    let message = translate('submitErrors.unknown')

    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
        style={{ visibility: submitError ? 'visible' : 'hidden' }}
      >
        {message}
      </Alert>
    )
  }

  useEffect(() => {
    setCheckedLights(initialValues.ledOn)
  }, [initialValues.ledOn])
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('editDeviceLights')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.secondPage}>
              <div className={styles.checkedLigthsWrapper}>
                <Typography className={styles.deviceLightsLabel}>
                  {translate('deviceLights')}
                </Typography>
                <Switch
                  checked={checkedLights}
                  defaultChecked={checkedLights}
                  onChange={handleChangeLightsSwitch}
                  name='checkedLights'
                  inputProps={{ 'aria-label': 'controlled' }}
                  inputRef={register()}
                  className={classes.switch}
                />
              </div>
              <div className={styles.infoPublicWrapper}>
                <div className={styles.iconPublic}>
                  <InfoOutlinedIcon />
                </div>
                <Typography className={styles.deviceLightsInfoLabel}>
                  {translate('deviceLigthsInfo')}
                </Typography>
              </div>
              <div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      onChange={handleSelectAllDevices}
                      inputRef={register()}
                      isChecked={allDeviceSelected}
                    />
                  }
                  label={translate('applyToAllDevices')}
                />
              </div>
              <div className={styles.infoPublicWrapper}>
                <div className={styles.iconPublic}>
                  <InfoOutlinedIcon />
                </div>
                <Typography className={styles.deviceLightsInfoLabel}>
                  {translate('applyToAllDevicesInfo')}
                </Typography>
              </div>
              {error()}
              <div className={styles.buttons}>
                <div className={styles.infoUpdateWrapper}>
                  <div className={styles.iconPublic}>
                    <FeedbackOutlinedIcon />
                  </div>
                  <Typography className={styles.deviceLightsInfoLabel}>
                    {translate('deviceUpdateInfo')}
                  </Typography>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    loading={isSubmitting}
                    loadingPosition='end'
                  >
                    {translate('saveChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    disableRipple
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditSystemLightsForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(EditSystemLightsForm)
