import _ from 'lodash'
import { toggleLoader } from '../common/actions/loader'

import { LOGIN_REQUEST, SIGN_UP_REQUEST } from 'common/actions/auth'

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST
} from 'common/actions/user'

import { UPDATE_SYSTEM_REQUEST } from 'common/actions/systems'

import { FORGOTTEN_PASSWORD_REQUEST, RECOVER_PASSWORD_REQUEST } from 'common/actions/password'

const showLoaderActions = []
const hideLoaderActions = []

const addAsyncActions = actionTypes => {
  actionTypes.forEach(actionType => {
    showLoaderActions.push(actionType.START)
    hideLoaderActions.push(actionType.SUCCESS, actionType.FAILURE)
  })
}

// Add your actions here...
addAsyncActions([
  // User actions
  LOGIN_REQUEST,
  SIGN_UP_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,

  // System actions
  UPDATE_SYSTEM_REQUEST,

  // Password actions
  FORGOTTEN_PASSWORD_REQUEST,
  RECOVER_PASSWORD_REQUEST
])

export default () => dispatch => action => {
  const type = action.type

  if (_.includes(showLoaderActions, type)) {
    dispatch(toggleLoader(true))
  }

  if (_.includes(hideLoaderActions, type)) {
    dispatch(toggleLoader(false))
  }

  return dispatch(action)
}
