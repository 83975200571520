import { call, put, all, take, takeLatest, fork, cancel, cancelled } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { normalize } from 'normalizr'

import actions, { constants } from 'common/actions/systems'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/system'
import modalActions from '../actions/modal'

const POLLING_DELAY = 120000 // 2 minutes
let pollSystemsTask = null

function* onGetSystems({ payload: data }) {
  yield put(actions.getSystemsRequest.start())

  try {
    const { systems } = yield call(api.getSystems, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.getSystemsRequest.success(norm))
  } catch (err) {
    yield put(actions.getSystemsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onGetSystemsHome({ payload: data }) {
  yield put(actions.getSystemsHomeRequest.start())

  try {
    const { systems } = yield call(api.getSystemsHome, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.getSystemsHomeRequest.success(norm))
  } catch (err) {
    yield put(actions.getSystemsHomeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateSystem({ payload: data }) {
  yield put(actions.updateSystemRequest.start())

  try {
    const { system } = yield call(api.updateSystem, data)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.updateSystemRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateSystemRequest.failure(err))
    if (err.responseError === undefined) yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    if (err.responseError.message === 'Unknown zip code') {
      yield put(snackbarActions.showZipcodeSnackbar('snackbar.zipCodeNotAvailable'))
    } else {
      yield put(snackbarActions.showSnackbar('snackbar.whoops'))
    }
  }
}

function* onUpdateSystemLights({ payload: data }) {
  yield put(actions.updateSystemLightsRequest.start())

  try {
    yield call(api.updateSystemLights, data._id, data)
    yield put(actions.updateSystemLightsRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateSystemLightsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateSystemPeriodicity({ payload: data }) {
  yield put(actions.updateSystemPeriodicityRequest.start())

  try {
    yield call(api.updateSystemPeriodicity, data._id, data)
    yield put(actions.updateSystemPeriodicityRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateSystemPeriodicityRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateSystemEnvironment({ payload: data }) {
  yield put(actions.updateSystemEnvironmentRequest.start())

  try {
    const { system } = yield call(api.updateSystem, data)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.updateSystemEnvironmentRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateSystemEnvironmentRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateMultipleSystemLights({ payload: data }) {
  yield put(actions.updateSystemGlobalLightsRequest.start())

  try {
    yield call(api.updateMultipleSystemLights, data)
    yield put(actions.updateSystemGlobalLightsRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateSystemGlobalLightsRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateMultipleSystemPeriodicity({ payload: data }) {
  yield put(actions.updateSystemGlobalPeriodicityRequest.start())

  try {
    yield call(api.updateMultipleSystemPeriodicity, data)
    yield put(actions.updateSystemGlobalPeriodicityRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.updateSystemGlobalPeriodicityRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateActiveProgram({ payload: data }) {
  yield put(actions.updateActiveProgramRequest.start())

  try {
    yield call(api.updateActiveProgram, data._id, data)
    yield put(actions.updateActiveProgramRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateActiveProgramRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateBypassMode({ payload: data }) {
  yield put(actions.updateBypassModeRequest.start())

  try {
    yield call(api.updateBypassMode, data._id, data)
    yield put(actions.updateBypassModeRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateBypassModeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateBypassOn({ payload: data }) {
  yield put(actions.updateBypassOnRequest.start())

  try {
    yield call(api.updateBypassOn, data._id, data)
    yield put(actions.updateBypassOnRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateBypassOnRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateBoostOn({ payload: data }) {
  yield put(actions.updateBoostOnRequest.start())

  try {
    yield call(api.updateBoostOn, data._id, data)
    yield put(actions.updateBoostOnRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateBoostOnRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateFanSpeed({ payload: data }) {
  yield put(actions.updateFanSpeedRequest.start())

  try {
    yield call(api.updateFanSpeed, data._id, data)
    yield put(actions.updateFanSpeedRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateFanSpeedRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateFilterTime({ payload: data }) {
  yield put(actions.updateFilterTimeRequest.start())

  try {
    yield call(api.updateFilterTime, data._id, data)
    yield put(actions.updateFilterTimeRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateFilterTimeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateWorkingMode({ payload: data }) {
  yield put(actions.updateWorkingModeRequest.start())
  try {
    yield call(api.updateWorkingMode, data._id, data)
    yield put(actions.updateWorkingModeRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateWorkingModeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateIngeniumMode({ payload: data }) {
  yield put(actions.updateIngeniumModeRequest.start())
  try {
    yield call(api.updateIngeniumMode, data._id, data)
    yield put(actions.updateIngeniumModeRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateIngeniumModeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdateIngeniumZones({ payload: data }) {
  yield put(actions.updateIngeniumZonesRequest.start())
  try {
    yield call(api.updateIngeniumZones, data._id, data)
    yield put(actions.updateIngeniumZonesRequest.success({ data }))
    window.location.reload()
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateIngeniumZonesRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdatePkomMode({ payload: data }) {
  yield put(actions.updatePkomModeRequest.start())
  try {
    yield call(api.updatePkomMode, data._id, data)
    yield put(actions.updatePkomModeRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updatePkomModeRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdatePkomClimaConsign({ payload: data }) {
  yield put(actions.updatePkomClimaConsignRequest.start())
  try {
    yield call(api.updatePkomClimaConsign, data._id, data)
    yield put(actions.updatePkomClimaConsignRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updatePkomClimaConsignRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdatePkomClimaVacation({ payload: data }) {
  yield put(actions.updatePkomClimaVacationRequest.start())
  try {
    yield call(api.updatePkomClimaVacation, data._id, data)
    yield put(actions.updatePkomClimaVacationRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updatePkomClimaVacationRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdatePkomACS({ payload: data }) {
  yield put(actions.updatePkomACSRequest.start())
  try {
    yield call(api.updatePkomACS, data._id, data)
    yield put(actions.updatePkomACSRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updatePkomACSRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onUpdatePkomVentilation({ payload: data }) {
  yield put(actions.updatePkomVentilationRequest.start())
  try {
    yield call(api.updatePkomVentilation, data._id, data)
    yield put(actions.updatePkomVentilationRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updatePkomVentilationRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

function* onPollSystems(id) {
  try {
    while (true) {
      yield put(actions.pollSystemsRequest.start())
      try {
        const { systems } = yield call(api.getSystemsHome, id)
        const norm = yield call(normalize, systems, [schemas.system])
        yield put(actions.pollSystemsRequest.success(norm))
        yield delay(POLLING_DELAY)
      } catch (err) {
        // Stop polling task when error
        yield put(actions.pollSystemsRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollSystemsStop())
      }
    }
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* watchPollSystems() {
  while (true) {
    const { payload: id } = yield take(constants.POLL_SYSTEMS_START)
    // Start polling task
    pollSystemsTask = yield fork(onPollSystems, id)

    yield take(constants.POLL_SYSTEMS_STOP)

    // Cancel polling task
    yield cancel(pollSystemsTask)
  }
}

function* onDeleteDataFromUser({ payload: systemId }) {
  yield put(actions.deleteDataFromUserRequest.start())

  try {
    yield call(api.deleteDataFromUser, systemId)
    yield put(actions.deleteDataFromUserRequest.success({ systemId }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
  } catch (err) {
    yield put(actions.deleteDataFromUserRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export default function* watchSystems() {
  yield fork(watchPollSystems)
  yield all([
    takeLatest(constants.GET_SYSTEMS, onGetSystems),
    takeLatest(constants.GET_SYSTEMS_HOME, onGetSystemsHome),
    takeLatest(constants.UPDATE_SYSTEM, onUpdateSystem),
    takeLatest(constants.UPDATE_SYSTEM_LIGHTS, onUpdateSystemLights),
    takeLatest(constants.UPDATE_SYSTEM_PERIODICITY, onUpdateSystemPeriodicity),
    takeLatest(constants.UPDATE_SYSTEM_ENVIRONMENT, onUpdateSystemEnvironment),
    takeLatest(constants.UPDATE_SYSTEM_GLOBAL_LIGHTS, onUpdateMultipleSystemLights),
    takeLatest(constants.UPDATE_SYSTEM_GLOBAL_PERIODICITY, onUpdateMultipleSystemPeriodicity),
    takeLatest(constants.UPDATE_ACTIVE_PROGRAM, onUpdateActiveProgram),
    takeLatest(constants.UPDATE_BYPASS_MODE, onUpdateBypassMode),
    takeLatest(constants.UPDATE_BYPASS_ON, onUpdateBypassOn),
    takeLatest(constants.UPDATE_BOOST_ON, onUpdateBoostOn),
    takeLatest(constants.UPDATE_FAN_SPEED, onUpdateFanSpeed),
    takeLatest(constants.UPDATE_FILTER_TIME, onUpdateFilterTime),
    takeLatest(constants.UPDATE_WORKING_MODE, onUpdateWorkingMode),
    takeLatest(constants.UPDATE_INGENIUM_MODE, onUpdateIngeniumMode),
    takeLatest(constants.UPDATE_INGENIUM_ZONES, onUpdateIngeniumZones),
    takeLatest(constants.DELETE_DATA_FROM_USER_SYSTEM, onDeleteDataFromUser),
    takeLatest(constants.UPDATE_PKOM_MODE, onUpdatePkomMode),
    takeLatest(constants.UPDATE_PKOM_CLIMA_CONSIGN, onUpdatePkomClimaConsign),
    takeLatest(constants.UPDATE_PKOM_CLIMA_VACATION, onUpdatePkomClimaVacation),
    takeLatest(constants.UPDATE_PKOM_VENTILATION, onUpdatePkomVentilation),
    takeLatest(constants.UPDATE_PKOM_ACS, onUpdatePkomACS)
  ])
}
