import request from 'utils/request'
import { Auth } from 'aws-amplify'

export function login(data) {
  return request('auth/', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export async function loginCognito(data) {
  try {
    return await Auth.signIn(data.email, data.password)
  } catch (error) {
    return error
  }
}

export async function getCognitoSession() {
  try {
    return await Promise.resolve(Auth.currentSession())
  } catch (error) {
    return false
  }
}

export async function logoutCognito() {
  try {
    await Auth.signOut()
  } catch (err) {}
}

export function signUp(data) {
  return request('users', {
    method: 'POST',
    data
  })
}

export async function signUpCognito(data) {
  try {
    const { email, password, language, channel } = data
    let attributes = {}
    const username = email
    const locale = language
    if (channel) {
      attributes = {
        email,
        locale,
        'custom:channel': channel
      }
    } else {
      attributes = {
        email,
        locale
      }
    }
    return await Auth.signUp({
      username,
      password,
      attributes
    })
  } catch (error) {
    return error
  }
}

export async function confirmSignUpCognito(data) {
  try {
    const { email, verificationCode } = data
    return await Auth.confirmSignUp(email, verificationCode)
  } catch (err) {
    return err
  }
}

export async function resendConfirmationMail(username) {
  try {
    await Auth.resendSignUp(username)
    return true
  } catch (err) {
    return err
  }
}

export function loadUser(id) {
  return request(`users/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}
