import { schema } from 'normalizr'

export const data = new schema.Entity(
  'data',
  {},
  {
    idAttribute: '_id'
  }
)

export const weather = new schema.Entity(
  'weather',
  {},
  {
    idAttribute: 'zipCode'
  }
)

export const system = new schema.Entity(
  'systems',
  {
    data: [data],
    weather
  },
  {
    idAttribute: '_id'
  }
)

export const user = new schema.Entity(
  'users',
  {
    systems: [system]
  },
  {
    idAttribute: '_id'
  }
)
