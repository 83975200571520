import { takeLatest, call, put, all } from 'redux-saga/effects'
import { push as pushHistory } from 'react-router-redux'

import actions, { constants } from '../actions/password'
import snackbarActions from 'common/actions/snackbar'

import * as api from 'api/password'
import * as Routes from 'constants/Routes'

export function* onForgottenPassword({ payload: data }) {
  yield put(actions.forgottenPasswordRequest.start())

  try {
    yield call(api.forgottenPassword, data)
    yield put(actions.forgottenPasswordRequest.success())
    yield put(snackbarActions.showSnackbar('snackbar.forgottenPasswordSuccess'))
    yield put(pushHistory(Routes.LOGIN))
  } catch (err) {
    yield put(actions.forgottenPasswordRequest.failure(err))
  }
}

export function* onCognitoForgottenPassword({ payload: data }) {
  yield put(actions.forgottenPasswordRequest.start())

  try {
    yield call(api.forgottenPasswordCognito, data)
    yield put(actions.forgottenPasswordRequest.success())
    yield put(snackbarActions.showSnackbar('snackbar.forgottenPasswordSuccess'))
    yield put(pushHistory(Routes.LOGIN))
  } catch (err) {
    yield put(actions.forgottenPasswordRequest.failure(err))
  }
}

export function* onRecoverPassword({ payload: data }) {
  yield put(actions.recoverPasswordRequest.start())

  try {
    yield call(api.recoverPassword, data)
    yield put(actions.recoverPasswordRequest.success())
    yield put(snackbarActions.showSnackbar('snackbar.recoverPasswordSuccess'))
    yield put(pushHistory(Routes.LOGIN))
  } catch (err) {
    yield put(actions.recoverPasswordRequest.failure(err))
  }
}

export function* onCognitoRecoverPassword({ payload: data }) {
  yield put(actions.recoverPasswordRequest.start())

  try {
    const result = yield call(api.recoverPasswordCognito, data)
    if (result && result.code !== undefined) {
      yield put(actions.recoverPasswordRequest.failure(result))
    } else {
      yield put(actions.recoverPasswordRequest.success())
      yield put(snackbarActions.showSnackbar('snackbar.recoverPasswordSuccess'))
      yield put(pushHistory(Routes.LOGIN))
    }
  } catch (err) {
    yield put(actions.recoverPasswordRequest.failure(err))
  }
}

export default function* watchPassword() {
  yield all([
    takeLatest(constants.FORGOTTEN_PASSWORD, onCognitoForgottenPassword),
    takeLatest(constants.RECOVER_PASSWORD, onCognitoRecoverPassword)
  ])
}
