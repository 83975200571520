import PropTypes from 'prop-types'
import styles from './MainLayout.module.scss'

import Header from '../../Header'
import Footer from '../../Footer'
import AuxHeader from '../../AuxHeader/AuxHeader'
// import SupportButton from '../../SupportButton/SupportButton'

const MainLayout = props => {
  return (
    <div className={styles.component}>
      <AuxHeader isLoggedIn />
      <Header isLoggedIn />
      <div className={styles.content}>
        {props.children}
        {/* <SupportButton /> */}
      </div>
      <Footer />
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node
}

export default MainLayout
