import ReactSelect from 'react-select'
import styles from './Select.module.scss'
import cx from 'classnames'

const Select = ({
  options,
  optionRenderer,
  valueRenderer,
  clearable = false,
  afterOnchange,
  onOpen = () => {},
  onClose = () => {},
  onChange,
  id,
  input,
  defaultValue,
  placeholder,
  className,
  rootClassName,
  required,
  disabled,
  label,
  helper,
  noErrors,
  meta,
  error,
  isSearchable = true
}) => {
  const hasError = error

  const selectState = cx(rootClassName, styles.component, {
    [styles.hasError]: hasError,
    [styles.required]: required,
    [styles.disabled]: disabled
  })

  return (
    <div className={selectState}>
      <ReactSelect
        id={id}
        onOpen={onOpen}
        onClose={onClose}
        maxMenuHeight={150}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        clearable={clearable}
        isDisabled={disabled}
        closeMenuOnSelect
        isSearchable={isSearchable}
        blurInputOnSelect
        className={cx('Select', className)}
        classNamePrefix='Select'
      />
    </div>
  )
}

Select.propTypes = {}

export default Select
