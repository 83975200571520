import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, browserHistory } from 'react-router'
import { getActiveLanguage, getTranslate, getLanguages } from 'react-localize-redux'

import { setActiveLanguage } from 'common/actions/localize'
import { getUser, getUserLang } from 'common/selectors/user'

import cx from 'classnames'
import styles from './Header.module.scss'
import { withStyles, StylesProvider } from '@material-ui/core/styles'
import * as ModalTypes from 'constants/ModalTypes'
import { showModal, hideModal } from 'common/actions/modal'

import * as Routes from 'constants/Routes'

import {
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  Fade,
  Button,
  Icon,
  SvgIcon,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import { InbiotBigLogo, InbiotLogo } from 'icons'
import Typography from '@material-ui/core/Typography'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { ReactComponent as ManageAccountsIcon } from 'icons/manage_accounts.svg'
import { ReactComponent as Environment } from 'icons/environment.svg'
import ZSaindariModels from 'constants/ZSaindariModels'
import { getSystemsRaw } from 'common/selectors/systems'

const navItems = [
  {
    translation: 'controlPanel',
    route: Routes.CONTROL_PANEL,
    isDisabled: false,
    isForPremium: false
  },
  {
    translation: 'measures',
    route: Routes.DASHBOARD,
    isDisabled: false,
    isForPremium: false
  }
]

const Header = props => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [userAnchorEl, setUserAnchorEl] = useState(null)

  const redirect = route => {
    browserHistory.push(route)
  }

  const handleLinkClick = item => {
    closeMobileMenu()
    const isPremium = user.toJS().isPremium
    if (item.isDisabled || (item.isForPremium && !isPremium)) return
    if (item.route !== Routes.NOT_FOUND) redirect(item.route)
  }

  const handleLogoClick = () => {
    redirect(Routes.HOME)
  }

  const handleMenuUserOpen = event => {
    if (userAnchorEl) {
      setUserAnchorEl(null)
    } else {
      setUserAnchorEl(event.currentTarget)
    }
  }

  const handleUserClick = option => {
    switch (option) {
      case 'edit-profile':
        setUserAnchorEl(null)
        redirect(Routes.PROFILE)
        break
      case 'logout':
        redirect(Routes.LOGOUT)
        break
      case 'change-env':
        setUserAnchorEl(false)
        let systemsJS = []
        props.systems.toArray().map(s => systemsJS.push(s.toJS()))
        const demoSystem = systemsJS.find(
          system => system._id === '70586c75ff8b614fa92a2a67de87ae386d19de5c'
        )
        props.showModal({
          type: ModalTypes.EDIT_ENVIRONMENT,
          props: {
            system: demoSystem
          }
        })
        break
      default:
        break
    }
  }

  const openMobileMenu = () => {
    if (!isMobileMenuOpen) {
      setMobileMenuOpen(true)
    } else {
      setMobileMenuOpen(false)
    }
  }

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  const isControlPanelVisible = () => {
    let visible = false
    let systemsJS = []
    props.systems.toArray().map(s => systemsJS.push(s.toJS()))
    for (let i = 0; i < systemsJS.length; i++) {
      if (systemsJS[i].model !== ZSaindariModels.ZSAINDARI) {
        visible = true
      }
    }
    return visible
  }

  const userDropDown = () => {
    const StyledMenu = withStyles(styles.menuUserPaper)(props => (
      <Menu
        {...props}
        classes={{ paper: styles.menuUserPaper }}
      />
    ))

    return (
      <div className={styles.userMenuWrapper}>
        <Button
          disableRipple
          aria-controls='user-menu'
          aria-haspopup='true'
          onClick={handleMenuUserOpen}
          classes={{ root: { textTransform: 'none' } }}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <PersonOutlineIcon className={styles.userIcon} />
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          id='user-menu'
          anchorEl={userAnchorEl}
          keepMounted
          open={Boolean(userAnchorEl)}
          onClose={() => setUserAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <MenuItem
            key='edit-profile'
            onClick={() => handleUserClick('edit-profile')}
            className={styles.menuItem}
          >
            <SvgIcon
              className={styles.userOptionsLogo}
              component={ManageAccountsIcon}
              viewBox='0 0 24 24'
            />
            <Typography className={styles.userOptionsText}>{translate('editProfile')}</Typography>
          </MenuItem>

          {user.toJS().email === 'demo@orkli.es' && (
            <MenuItem
              key='change-env'
              onClick={() => handleUserClick('change-env')}
              className={styles.menuItem}
            >
              <SvgIcon
                className={styles.userOptionsLogo}
                component={Environment}
                viewBox='0 0 700 700'
              />
              <Typography className={styles.userOptionsText}>{translate('changeEnv')}</Typography>
            </MenuItem>
          )}

          <MenuItem
            key='logout'
            onClick={() => handleUserClick('logout')}
            className={styles.menuItem}
          >
            <ExitToAppOutlinedIcon className={styles.userOptionsLogo} />
            <Typography className={styles.userOptionsText}>{translate('logout')}</Typography>
          </MenuItem>
        </StyledMenu>
      </div>
    )
  }

  const { isLoggedIn, translate, user } = props
  const pathname = props.location.pathname

  return (
    <StylesProvider injectFirst>
      <AppBar className={styles.component}>
        <Toolbar className={styles.content}>
          <div className={styles.logoWrapper}>
            <Icon
              onClick={() => redirect(Routes.HOME)}
              className={styles.logo}
            >
              <img
                className={styles.logoImage}
                src={InbiotLogo}
                alt=''
              />
            </Icon>
          </div>
          <div className={styles.nav}>
            {isLoggedIn &&
              navItems.map((item, index) => {
                if (pathname === Routes.HOME) return
                const isActive = pathname === item.route
                const isPremium = isLoggedIn && user.toJS().isPremium
                if (item.route === Routes.CONTROL_PANEL && !isControlPanelVisible()) return
                return (
                  <div
                    key={index}
                    className={cx(styles.navItem, {
                      [styles.activeNavItem]: isActive,
                      [styles.disabledNavItem]: item.isDisabled || (item.isForPremium && !isPremium)
                    })}
                    onClick={() => handleLinkClick(item)}
                  >
                    {!isPremium && item.isForPremium && (
                      <Tooltip
                        title={translate('businessPlan')}
                        placement='bottom'
                        arrow='true'
                      >
                        <span>{translate(item.translation)}</span>
                      </Tooltip>
                    )}
                    {item.comingSoon && (
                      <Tooltip
                        title={translate('comingSoon')}
                        placement='bottom'
                        arrow='true'
                      >
                        <span>{translate(item.translation)}</span>
                      </Tooltip>
                    )}
                    {!item.comingSoon &&
                      (isPremium || !item.isForPremium) &&
                      translate(item.translation)}
                  </div>
                )
              })}
          </div>

          <div className={styles.mobileNav}>
            {isLoggedIn && !isMobileMenuOpen && (
              <MenuIcon
                onClick={openMobileMenu}
                className={styles.mobileMenuIcon}
              />
            )}

            {isLoggedIn && isMobileMenuOpen && (
              <CloseIcon
                onClick={closeMobileMenu}
                className={styles.mobileMenuIcon}
              />
            )}

            <div
              className={cx(styles.mobileMenu, {
                [styles.mobileMenuOpen]: isMobileMenuOpen
              })}
            >
              <Fade in={isMobileMenuOpen}>
                <div
                  className={cx(styles.mobileMenuContent, {
                    [styles.mobileMenuContentClose]: !isMobileMenuOpen
                  })}
                >
                  {isLoggedIn &&
                    navItems.map((item, index) => {
                      if (pathname === Routes.HOME) return
                      const isActive = pathname === item.route
                      const isPremium = isLoggedIn && user.toJS().isPremium
                      if (item.route === Routes.CONTROL_PANEL && !isControlPanelVisible()) return
                      return (
                        <div
                          key={index}
                          className={cx(styles.mobileNavItem, {
                            [styles.activeMobileNavItem]: isActive,
                            [styles.disabledMobileNavItem]:
                              item.isDisabled || (item.isForPremium && !isPremium)
                          })}
                          onClick={() => handleLinkClick(item)}
                        >
                          {translate(item.translation)}
                        </div>
                      )
                    })}

                  <hr className={styles.mobileMenuDivider} />

                  <Button
                    disableRipple
                    className={cx(styles.navItem, styles.buttonLanguages)}
                    onClick={() => window.open('https://www.orkli.com/en/contact', '_blank')}
                  >
                    <Typography className={styles.helpButtonText}>{translate('help')}</Typography>
                  </Button>
                </div>
              </Fade>
            </div>
          </div>

          <div className={styles.mobileLogo}>
            <Icon
              className={styles.logoMobile}
              onClick={handleLogoClick}
            >
              <img
                className={styles.logoImageMobile}
                src={InbiotBigLogo}
                alt=''
              />
            </Icon>
          </div>

          {isLoggedIn && !isMobileMenuOpen && userDropDown()}
        </Toolbar>
      </AppBar>
    </StylesProvider>
  )
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize),
  systems: getSystemsRaw(state)
})

const mapActionsToProps = {
  setActiveLanguage,
  showModal,
  hideModal
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Header))
