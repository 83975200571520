import { useState } from 'react'
import { connect } from 'react-redux'
import { getActiveLanguage, getLanguages, getTranslate, withLocalize } from 'react-localize-redux'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import { Button, Fade, Menu, MenuItem } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'

import styles from './Footer.module.scss'
import { getUser, getUserLang } from 'common/selectors/user'
import { setActiveLanguage } from 'common/actions/localize'
import { getUserMeasurementSystem } from 'utils/auxStore'
import { setActiveMeasurementSystem } from 'common/actions/measurementSystem'

const Footer = props => {
  const { translate } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [measurementSystemAnchorEl, setMeasurementSystemAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseMeasurementSystemDropdown = () => {
    setMeasurementSystemAnchorEl(null)
  }

  const handleMenuLanguageOpen = event => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMenuMeasurementSystemOpen = event => {
    if (measurementSystemAnchorEl) {
      setMeasurementSystemAnchorEl(null)
    } else {
      setMeasurementSystemAnchorEl(event.currentTarget)
    }
  }

  const handleLanguageClick = option => {
    props.setActiveLanguage(option.toLowerCase())
    setAnchorEl(null)
  }

  const handleMeasurementSystemClick = option => {
    props.setActiveMeasurementSystem(option)
  }

  const handlePolicyLink = () => {
    window.open('https://www.orkli.com/es/politica-privacidad')
  }

  const languageDropDown = () => {
    const { languages, activeLanguage, userLanguage } = props
    const lang = userLanguage ? userLanguage.toUpperCase() : activeLanguage.name

    const dropDownItems = languages

    const StyledMenu = withStyles(styles.menuLanguagePaper)(props => (
      <Menu
        id='menu-language'
        {...props}
        classes={{
          paper: styles.menuLanguagePaper,
          list: styles.menuLanguageContent
        }}
      />
    ))

    return (
      <div>
        <Button
          disableRipple
          aria-controls='fade-menu'
          aria-haspopup='true'
          onClick={handleMenuLanguageOpen}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <LanguageOutlinedIcon style={{ width: '18px' }} />
          <Typography className={styles.languageTitle}>{translate(lang.toString())}</Typography>
          {Boolean(anchorEl) && (
            <KeyboardArrowDownIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
          {!anchorEl && (
            <KeyboardArrowUpIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          className={styles.languageMenu}
        >
          {dropDownItems.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleLanguageClick(option.name)}
              className={styles.menuItem}
            >
              <div className={styles.languageSelectedIcon}>
                {lang === option.name && <CheckOutlinedIcon style={{ width: '20px' }} />}
              </div>
              {translate(option.name.toString())}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    )
  }

  const measurementSystemDropdown = () => {
    const userMeasurementSystem = getUserMeasurementSystem()

    const measurementSystemOptions = [
      {
        label: translate('metricSystem'),
        value: 'mS'
      },
      {
        label: translate('imperialSystem'),
        value: 'iS'
      }
    ]

    const StyledMenu = withStyles(styles.menuMeasurementsSystemPaper)(props => (
      <Menu
        id='menu-measurements-system'
        {...props}
        classes={{
          paper: styles.menuMeasurementsSystemPaper,
          list: styles.menuLanguageContent
        }}
      />
    ))

    return (
      <div style={{ marginLeft: 'auto' }}>
        <Button
          disableRipple
          aria-controls='fade-menu'
          aria-haspopup='true'
          onClick={handleMenuMeasurementSystemOpen}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <LanguageOutlinedIcon style={{ width: '18px' }} />
          <Typography className={styles.measurementSystemTitle}>
            {userMeasurementSystem === 'iS'
              ? translate('imperialSystem')
              : translate('metricSystem')}
          </Typography>
          {Boolean(measurementSystemAnchorEl) && (
            <KeyboardArrowDownIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
          {!measurementSystemAnchorEl && (
            <KeyboardArrowUpIcon style={{ opacity: '0.8', width: '20px', paddingLeft: '4px' }} />
          )}
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          id='measurement-system-menu'
          anchorEl={measurementSystemAnchorEl}
          keepMounted
          open={Boolean(measurementSystemAnchorEl)}
          onClose={handleCloseMeasurementSystemDropdown}
          TransitionComponent={Fade}
          className={styles.languageMenu}
        >
          {measurementSystemOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMeasurementSystemClick(option.value)}
              className={styles.menuItem}
            >
              <div className={styles.languageSelectedIcon}>
                {userMeasurementSystem === option.value && (
                  <CheckOutlinedIcon style={{ width: '20px' }} />
                )}
              </div>
              {option.label}
            </MenuItem>
          ))}
        </StyledMenu>
      </div>
    )
  }
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <div className={styles.languageDropdown}>
          {languageDropDown()}
          {measurementSystemDropdown()}
        </div>
        <hr className={styles.footerSeparator} />
        <div className={styles.footerContent}>
          <div className={styles.contact}>
            <div>
              <a
                className={styles.link}
                rel='noopener'
                onClick={ev => {
                  ev.preventDefault()
                  handlePolicyLink()
                }}
                href='/#'
              >
                {translate('acceptPolicyandTerms.policy')}
              </a>
            </div>
          </div>
          <div className={styles.copyright}>
            <p className={styles.copyrightText}>{translate('copyright')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  setActiveLanguage,
  setActiveMeasurementSystem
}

export default connect(mapStateToProps, mapActionsToProps)(withLocalize(Footer))
