export const MAIN = '/'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const SIGN_UP = '/sign-up'
export const CONFIRM_USER = '/confirm-user'
export const CONFIRM_USER_INFO = '/confirm-user-info'
export const FORGOTTEN_PASSWORD = '/forgotten-password'
export const RECOVER_PASSWORD = '/recover-password'
export const DASHBOARD = '/dashboard'
export const PROFILE = '/profile'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'
export const NOT_FOUND = '/not-found'
export const POLICY = '/policy'
export const HOME = '/home'
export const CONTROL_PANEL = '/control-panel'
export const CHANNEL = '/channel'
export const MORE_INFORMATION = '/more-information'
