import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const GET_SYSTEMS = '@system/GET_SYSTEMS'
export const GET_SYSTEMS_HOME = '@system/GET_SYSTEMS_HOME'
export const UPDATE_SYSTEM = '@system/UPDATE_SYSTEM'
export const UPDATE_SYSTEM_LIGHTS = '@system/UPDATE_SYSTEM_LIGHTS'
export const UPDATE_SYSTEM_GLOBAL_LIGHTS = '@system/UPDATE_SYSTEM_GLOBAL_LIGHTS'
export const UPDATE_SYSTEM_PERIODICITY = '@system/UPDATE_SYSTEM_PERIODICITY'
export const UPDATE_SYSTEM_GLOBAL_PERIODICITY = '@system/UPDATE_SYSTEM_GLOBAL_PERIODICITY'
export const UPDATE_ACTIVE_PROGRAM = '@system/UPDATE_ACTIVE_PROGRAM'
export const UPDATE_BYPASS_MODE = '@system/UPDATE_BYPASS_MODE'
export const UPDATE_BYPASS_ON = '@system/UPDATE_BYPASS_ON'
export const UPDATE_BOOST_ON = '@system/UPDATE_BOOST_ON'
export const UPDATE_FAN_SPEED = '@system/UPDATE_FAN_SPEED'
export const UPDATE_FILTER_TIME = '@system/UPDATE_FILTER_TIME'
export const UPDATE_WORKING_MODE = '@system/UPDATE_WORKING_MODE'
export const UPDATE_INGENIUM_MODE = '@system/UPDATE_INGENIUM_MODE'
export const UPDATE_INGENIUM_ZONES = '@system/UPDATE_INGENIUM_ZONES'
export const UPDATE_SYSTEM_ENVIRONMENT = '@system/UPDATE_SYSTEM_ENVIRONMENT'
export const UPDATE_PKOM_MODE = '@system/UPDATE_PKOM_MODE'
export const UPDATE_PKOM_CLIMA_CONSIGN = '@system/UPDATE_PKOM_CLIMA_CONSIGN'
export const UPDATE_PKOM_CLIMA_VACATION = '@system/UPDATE_PKOM_CLIMA_VACATION'
export const UPDATE_PKOM_ACS = '@system/UPDATE_PKOM_ACS'
export const UPDATE_PKOM_VENTILATION = '@system/UPDATE_PKOM_VENTILATION'

export const POLL_SYSTEMS_START = '@system/POLL_SYSTEMS_START'
export const POLL_SYSTEMS_STOP = '@system/POLL_SYSTEMS_STOP'
export const DELETE_DATA_FROM_USER_SYSTEM = '@system/DELETE_DATA_FROM_USER_SYSTEM'

export const GET_SYSTEMS_REQUEST = createRequestTypes('@system/GET_SYSTEMS_REQUEST')
export const GET_SYSTEMS_HOME_REQUEST = createRequestTypes('@system/GET_SYSTEMS_HOME_REQUEST')
export const UPDATE_SYSTEM_REQUEST = createRequestTypes('@system/UPDATE_SYSTEM')
export const UPDATE_SYSTEM_LIGHTS_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_LIGHTS_REQUEST'
)
export const UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST'
)
export const UPDATE_SYSTEM_PERIODICITY_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_PERIODICITY_REQUEST'
)
export const UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST'
)
export const UPDATE_ACTIVE_PROGRAM_REQUEST = createRequestTypes(
  '@system/UPDATE_ACTIVE_PROGRAM_REQUEST'
)
export const UPDATE_BYPASS_MODE_REQUEST = createRequestTypes('@system/UPDATE_BYPASS_MODE_REQUEST')
export const UPDATE_BYPASS_ON_REQUEST = createRequestTypes('@system/UPDATE_BYPASS_ON_REQUEST')
export const UPDATE_BOOST_ON_REQUEST = createRequestTypes('@system/UPDATE_BOOST_ON_REQUEST')
export const UPDATE_FAN_SPEED_REQUEST = createRequestTypes('@system/UPDATE_FAN_SPEED_REQUEST')
export const UPDATE_FILTER_TIME_REQUEST = createRequestTypes('@system/UPDATE_FILTER_TIME_REQUEST')
export const UPDATE_WORKING_MODE_REQUEST = createRequestTypes('@system/UPDATE_WORKING_MODE_REQUEST')
export const UPDATE_INGENIUM_MODE_REQUEST = createRequestTypes(
  '@system/UPDATE_INGENIUM_MODE_REQUEST'
)
export const UPDATE_INGENIUM_ZONES_REQUEST = createRequestTypes(
  '@system/UPDATE_INGENIUM_ZONES_REQUEST'
)
export const UPDATE_SYSTEM_ENVIRONMENT_REQUEST = createRequestTypes(
  '@system/UPDATE_SYSTEM_ENVIRONMENT_REQUEST'
)
export const UPDATE_PKOM_MODE_REQUEST = createRequestTypes('@system/UPDATE_PKOM_MODE_REQUEST')
export const UPDATE_PKOM_CLIMA_CONSIGN_REQUEST = createRequestTypes(
  '@system/UPDATE_PKOM_CLIMA_CONSIGN_REQUEST'
)
export const UPDATE_PKOM_CLIMA_VACATION_REQUEST = createRequestTypes(
  '@system/UPDATE_PKOM_CLIMA_VACATION_REQUEST'
)
export const UPDATE_PKOM_ACS_REQUEST = createRequestTypes('@system/UPDATE_PKOM_ACS_REQUEST')
export const UPDATE_PKOM_VENTILATION_REQUEST = createRequestTypes(
  '@system/UPDATE_PKOM_VENTILATION_REQUEST'
)

export const POLL_SYSTEMS_REQUEST = createRequestTypes('@system/POLL_SYSTEMS_REQUEST')
export const DELETE_DATA_FROM_USER_SYSTEM_REQUEST = createRequestTypes(
  '@system/DELETE_DATA_FROM_USER_SYSTEM_REQUEST'
)

export const constants = {
  GET_SYSTEMS,
  GET_SYSTEMS_HOME,
  UPDATE_SYSTEM,
  UPDATE_SYSTEM_LIGHTS,
  UPDATE_SYSTEM_GLOBAL_LIGHTS,
  UPDATE_SYSTEM_PERIODICITY,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY,
  UPDATE_ACTIVE_PROGRAM,
  UPDATE_BYPASS_MODE,
  UPDATE_BYPASS_ON,
  UPDATE_BOOST_ON,
  UPDATE_FAN_SPEED,
  UPDATE_FILTER_TIME,
  UPDATE_WORKING_MODE,
  UPDATE_INGENIUM_MODE,
  UPDATE_INGENIUM_ZONES,
  UPDATE_SYSTEM_ENVIRONMENT,
  UPDATE_PKOM_MODE,
  UPDATE_PKOM_CLIMA_CONSIGN,
  UPDATE_PKOM_CLIMA_VACATION,
  UPDATE_PKOM_ACS,
  UPDATE_PKOM_VENTILATION,

  POLL_SYSTEMS_START,
  POLL_SYSTEMS_STOP,
  DELETE_DATA_FROM_USER_SYSTEM,

  GET_SYSTEMS_REQUEST,
  GET_SYSTEMS_HOME_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_LIGHTS_REQUEST,
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST,
  UPDATE_SYSTEM_PERIODICITY_REQUEST,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST,
  UPDATE_ACTIVE_PROGRAM_REQUEST,
  UPDATE_BYPASS_MODE_REQUEST,
  UPDATE_BYPASS_ON_REQUEST,
  UPDATE_FAN_SPEED_REQUEST,
  UPDATE_FILTER_TIME_REQUEST,
  UPDATE_WORKING_MODE_REQUEST,
  UPDATE_INGENIUM_MODE_REQUEST,
  UPDATE_INGENIUM_ZONES_REQUEST,
  UPDATE_SYSTEM_ENVIRONMENT_REQUEST,
  UPDATE_PKOM_MODE_REQUEST,
  UPDATE_PKOM_CLIMA_CONSIGN_REQUEST,
  UPDATE_PKOM_CLIMA_VACATION_REQUEST,
  UPDATE_PKOM_ACS_REQUEST,
  UPDATE_PKOM_VENTILATION_REQUEST,

  POLL_SYSTEMS_REQUEST,
  DELETE_DATA_FROM_USER_SYSTEM_REQUEST
}

export const getSystems = createAction(GET_SYSTEMS)
export const getSystemsHome = createAction(GET_SYSTEMS_HOME)
export const updateSystem = createAction(UPDATE_SYSTEM)
export const updateSystemLights = createAction(UPDATE_SYSTEM_LIGHTS)
export const updateSystemGlobalLights = createAction(UPDATE_SYSTEM_GLOBAL_LIGHTS)
export const updateSystemPeriodicity = createAction(UPDATE_SYSTEM_PERIODICITY)
export const updateSystemGlobalPeriodicity = createAction(UPDATE_SYSTEM_GLOBAL_PERIODICITY)
export const updateActiveProgram = createAction(UPDATE_ACTIVE_PROGRAM)
export const updateBypassMode = createAction(UPDATE_BYPASS_MODE)
export const updateBypassOn = createAction(UPDATE_BYPASS_ON)
export const updateBoostOn = createAction(UPDATE_BOOST_ON)

export const updateFanSpeed = createAction(UPDATE_FAN_SPEED)
export const updateFilterTime = createAction(UPDATE_FILTER_TIME)
export const updateWorkingMode = createAction(UPDATE_WORKING_MODE)
export const updateIngeniumMode = createAction(UPDATE_INGENIUM_MODE)
export const updateIngeniumZones = createAction(UPDATE_INGENIUM_ZONES)
export const updateSystemEnvironment = createAction(UPDATE_SYSTEM_ENVIRONMENT)
export const updatePkomMode = createAction(UPDATE_PKOM_MODE)
export const updatePkomClimaConsign = createAction(UPDATE_PKOM_CLIMA_CONSIGN)
export const updatePkomClimaVacation = createAction(UPDATE_PKOM_CLIMA_VACATION)
export const updatePkomACS = createAction(UPDATE_PKOM_ACS)
export const updatePkomVentilation = createAction(UPDATE_PKOM_VENTILATION)

export const pollSystemsStart = createAction(POLL_SYSTEMS_START)
export const pollSystemsStop = createAction(POLL_SYSTEMS_STOP)
export const deleteDataFromUser = createAction(DELETE_DATA_FROM_USER_SYSTEM)

export const getSystemsRequest = createRequestAction(GET_SYSTEMS_REQUEST)
export const getSystemsHomeRequest = createRequestAction(GET_SYSTEMS_HOME_REQUEST)
export const updateSystemRequest = createRequestAction(UPDATE_SYSTEM_REQUEST)
export const updateSystemLightsRequest = createRequestAction(UPDATE_SYSTEM_LIGHTS_REQUEST)
export const updateSystemGlobalLightsRequest = createRequestAction(
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST
)
export const updateSystemPeriodicityRequest = createRequestAction(UPDATE_SYSTEM_PERIODICITY_REQUEST)
export const updateSystemGlobalPeriodicityRequest = createRequestAction(
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST
)
export const updateActiveProgramRequest = createRequestAction(UPDATE_ACTIVE_PROGRAM_REQUEST)
export const updateBypassModeRequest = createRequestAction(UPDATE_BYPASS_MODE_REQUEST)
export const updateBypassOnRequest = createRequestAction(UPDATE_BYPASS_ON_REQUEST)
export const updateBoostOnRequest = createRequestAction(UPDATE_BOOST_ON_REQUEST)
export const updateFanSpeedRequest = createRequestAction(UPDATE_FAN_SPEED_REQUEST)
export const updateFilterTimeRequest = createRequestAction(UPDATE_FILTER_TIME_REQUEST)
export const updateWorkingModeRequest = createRequestAction(UPDATE_WORKING_MODE_REQUEST)
export const updateIngeniumModeRequest = createRequestAction(UPDATE_INGENIUM_MODE_REQUEST)
export const updateIngeniumZonesRequest = createRequestAction(UPDATE_INGENIUM_ZONES_REQUEST)
export const updateSystemEnvironmentRequest = createRequestAction(UPDATE_SYSTEM_ENVIRONMENT_REQUEST)
export const updatePkomModeRequest = createRequestAction(UPDATE_PKOM_MODE_REQUEST)
export const updatePkomClimaConsignRequest = createRequestAction(UPDATE_PKOM_CLIMA_CONSIGN_REQUEST)
export const updatePkomClimaVacationRequest = createRequestAction(
  UPDATE_PKOM_CLIMA_VACATION_REQUEST
)
export const updatePkomACSRequest = createRequestAction(UPDATE_PKOM_ACS_REQUEST)
export const updatePkomVentilationRequest = createRequestAction(UPDATE_PKOM_VENTILATION_REQUEST)

export const pollSystemsRequest = createRequestAction(POLL_SYSTEMS_REQUEST)
export const deleteDataFromUserRequest = createRequestAction(DELETE_DATA_FROM_USER_SYSTEM_REQUEST)

export default {
  getSystems,
  getSystemsHome,
  updateSystem,
  updateSystemLights,
  updateSystemGlobalLights,
  updateSystemPeriodicity,
  updateSystemGlobalPeriodicity,
  updateActiveProgram,
  updateBypassMode,
  updateBypassOn,
  updateBoostOn,
  updateFanSpeed,
  updateFilterTime,
  updateWorkingMode,
  updateIngeniumMode,
  updateIngeniumZones,
  pollSystemsStart,
  pollSystemsStop,
  deleteDataFromUser,
  updateSystemEnvironment,
  updatePkomMode,
  updatePkomClimaConsign,
  updatePkomClimaVacation,
  updatePkomACS,
  updatePkomVentilation,

  getSystemsRequest,
  getSystemsHomeRequest,
  updateSystemRequest,
  updateSystemLightsRequest,
  updateSystemGlobalLightsRequest,
  updateSystemPeriodicityRequest,
  updateSystemGlobalPeriodicityRequest,
  updateActiveProgramRequest,
  updateBypassModeRequest,
  updateBypassOnRequest,
  updateBoostOnRequest,
  updateFanSpeedRequest,
  updateFilterTimeRequest,
  updateWorkingModeRequest,
  updateIngeniumModeRequest,
  updateIngeniumZonesRequest,
  pollSystemsRequest,
  deleteDataFromUserRequest,
  updateSystemEnvironmentRequest,
  updatePkomModeRequest,
  updatePkomClimaConsignRequest,
  updatePkomClimaVacationRequest,
  updatePkomACSRequest,
  updatePkomVentilationRequest
}
