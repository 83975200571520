import { fromJS } from 'immutable'
import { createReducer } from 'utils'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from 'common/actions/auth'

import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  CONFIRM_USER_NEWS_REQUEST
} from 'common/actions/user'

import {
  GET_SYSTEMS_REQUEST,
  GET_SYSTEMS_HOME_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  DELETE_DATA_FROM_USER_SYSTEM_REQUEST,
  UPDATE_SYSTEM_LIGHTS_REQUEST,
  UPDATE_SYSTEM_PERIODICITY_REQUEST,
  UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST,
  UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST,
  UPDATE_ACTIVE_PROGRAM_REQUEST,
  UPDATE_BYPASS_MODE_REQUEST,
  UPDATE_BYPASS_ON_REQUEST,
  UPDATE_BOOST_ON_REQUEST,
  UPDATE_FAN_SPEED_REQUEST,
  UPDATE_FILTER_TIME_REQUEST,
  UPDATE_WORKING_MODE_REQUEST,
  UPDATE_INGENIUM_MODE_REQUEST,
  UPDATE_INGENIUM_ZONES_REQUEST,
  UPDATE_SYSTEM_ENVIRONMENT_REQUEST,
  UPDATE_PKOM_CLIMA_CONSIGN_REQUEST,
  UPDATE_PKOM_MODE_REQUEST,
  UPDATE_PKOM_ACS_REQUEST,
  UPDATE_PKOM_CLIMA_VACATION_REQUEST
} from 'common/actions/systems'

export const initialState = fromJS({
  users: {},
  systems: {},
  data: {},
  weather: {}
})

const mergeEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const setUser = (state, { payload }) => {
  return state.setIn(
    ['users', String(payload.result)],
    fromJS(payload.entities.users[payload.result])
  )
}

export default createReducer(initialState, {
  // User actions
  [LOGOUT]: () => initialState,
  [AUTH_USER_REQUEST.SUCCESS]: mergeEntities,
  [LOGIN_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_USER_REQUEST.SUCCESS]: setUser,
  [CONFIRM_USER_NEWS_REQUEST.SUCCESS]: setUser,

  [UPDATE_USER_PASSWORD_REQUEST.SUCCESS]: setUser,
  [ADD_SYSTEM_TO_USER_REQUEST.SUCCESS]: mergeEntities,
  [DELETE_SYSTEM_FROM_USER_REQUEST.SUCCESS]: (state, { payload: { userId, systemId } }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    // Manually remove:
    // - system from systems
    // - all data from system
    return state
      .updateIn(['systems'], systems => systems.filter(s => s.get('_id') !== systemId))
      .updateIn(['data'], data => data.filter(d => !dataIds.includes(d.get('_id'))))
  },

  // System actions
  [GET_SYSTEMS_REQUEST.SUCCESS]: mergeEntities,
  [GET_SYSTEMS_HOME_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_ENVIRONMENT_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('timeInterval', timeInterval)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_LIGHTS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const ledOn = data.ledOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('ledOn', ledOn)
      })
    )
  },
  [UPDATE_SYSTEM_GLOBAL_PERIODICITY_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const timeInterval = data.timeInterval
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        return s.set('timeInterval', timeInterval)
      })
    )
  },
  [UPDATE_ACTIVE_PROGRAM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const activeProgram = data.activeProgram
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlVariables = s.toJS().controlVariables
        controlVariables.activeProgram = activeProgram
        return s.set('controlVariables', controlVariables)
      })
    )
  },
  [UPDATE_BYPASS_MODE_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const bypassMode = data.bypassMode
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlVariables = s.toJS().controlVariables
        controlVariables.bypassMode = bypassMode
        return s.set('controlVariables', controlVariables)
      })
    )
  },
  [UPDATE_BYPASS_ON_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const bypassOn = data.bypassOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlSlim = s.toJS().controlSlim
        controlSlim.bypassOn = bypassOn
        return s.set('controlSlim', controlSlim)
      })
    )
  },
  [UPDATE_BOOST_ON_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const boostOn = data.boostOn
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlSlim = s.toJS().controlSlim
        controlSlim.boostOn = boostOn
        return s.set('controlSlim', controlSlim)
      })
    )
  },
  [UPDATE_FAN_SPEED_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const controlModel = data.data.model.includes('SLIM') ? 'controlSlim' : 'controlVariables'
    const deviceId = data._id
    const fanSpeed = data.data.fanSpeed
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let modelVariables = s.toJS()[controlModel]
        modelVariables.fanSpeed = fanSpeed
        return s.set(controlModel, modelVariables)
      })
    )
  },
  [UPDATE_FILTER_TIME_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const filterTime = data.filterTime
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlVariables = s.toJS().controlVariables
        controlVariables.filterTime = filterTime
        return s.set('controlVariables', controlVariables)
      })
    )
  },
  [UPDATE_WORKING_MODE_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const controlModel = data.data.model.includes('SLIM') ? 'controlSlim' : 'controlVariables'
    const deviceId = data._id
    const mode = data.data.mode
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let modelVariables = s.toJS()[controlModel]
        modelVariables.mode = mode
        return s.set(controlModel, modelVariables)
      })
    )
  },

  [UPDATE_INGENIUM_MODE_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const mode = data.mode
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlIngenium = s.toJS().controlIngenium
        controlIngenium.mode = mode
        return s.set('controlIngenium', controlIngenium)
      })
    )
  },

  [UPDATE_INGENIUM_ZONES_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const zones = data.zones
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlIngenium = s.toJS().controlIngenium
        controlIngenium.zones = zones
        return s.set('controlIngenium', controlIngenium)
      })
    )
  },

  [UPDATE_PKOM_CLIMA_CONSIGN_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const consignTempSummer = data.consignTempSummer
    const consignTempWinter = data.consignTempWinter
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlPkomOptions = s.toJS().controlPkomOptions || {}
        let controlPkom = s.toJS().controlPkom
        controlPkomOptions.clima.consignTempSummer = consignTempSummer
        controlPkomOptions.clima.consignTempWinter = consignTempWinter

        controlPkom.clima.consignTempCooling = consignTempSummer
        controlPkom.clima.consignTemp = consignTempWinter
        return s.set('controlPkomOptions', controlPkomOptions).set('controlPkom', controlPkom)
      })
    )
  },
  [UPDATE_PKOM_CLIMA_VACATION_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const dateReturn = data.vacationDate
    const temperatureHoliday = data.consignTempVacation
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlPkomOptions = s.toJS().controlPkomOptions || {}
        controlPkomOptions.clima.consignTempVacation = temperatureHoliday
        controlPkomOptions.clima.vacationDate = dateReturn
        return s.set('controlPkomOptions', controlPkomOptions)
      })
    )
  },
  [UPDATE_PKOM_MODE_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const operationMode = data.operationMode

    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlPkom = s.toJS().controlPkom
        controlPkom.operationMode = operationMode
        controlPkom.clima.operationModeAuto = operationMode
        return s.set('controlPkom', controlPkom)
      })
    )
  },

  [UPDATE_PKOM_ACS_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    const deviceId = data._id
    const consignTemp = data.consignTemp
    const electricalResistance = data.electricalResistance

    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        let controlPkom = s.toJS().controlPkom
        controlPkom.ACS.consignTemp = consignTemp
        controlPkom.ACS.electricalResistance = electricalResistance

        return s.set('controlPkom', controlPkom)
      })
    )
  },

  [DELETE_DATA_FROM_USER_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { systemId } }) => {
    const system = state.getIn(['systems', String(systemId)])
    const dataIds = system.get('data')

    return state.updateIn(['data'], data =>
      data.map(d => {
        if (!dataIds.includes(d.get('_id'))) return d

        return d.set('measurements', [])
      })
    )
  },

  [POLL_SYSTEMS_REQUEST.SUCCESS]: mergeEntities
})
