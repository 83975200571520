import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const UPDATE_USER = '@user/UPDATE_USER'
export const UPDATE_USER_PASSWORD = '@user/UPDATE_USER_PASSWORD'
export const ADD_SYSTEM_TO_USER = '@user/ADD_SYSTEM_TO_USER'
export const DELETE_SYSTEM_FROM_USER = '@user/DELETE_SYSTEM_FROM_USER'
export const CONFIRM_USER_NEWS = '@user/CONFIRM_USER_NEWS'
export const DELETE_ACCOUNT = '@user/DELETE_ACCOUNT'

export const UPDATE_USER_REQUEST = createRequestTypes('@user/UPDATE_USER_REQUEST')
export const UPDATE_USER_PASSWORD_REQUEST = createRequestTypes('@user/UPDATE_USER_PASSWORD_REQUEST')
export const ADD_SYSTEM_TO_USER_REQUEST = createRequestTypes('@user/ADD_SYSTEM_TO_USER_REQUEST')
export const DELETE_SYSTEM_FROM_USER_REQUEST = createRequestTypes(
  '@user/DELETE_SYSTEM_FROM_USER_REQUEST'
)
export const CONFIRM_USER_NEWS_REQUEST = createRequestTypes('@user/CONFIRM_USER_NEWS_REQUEST')
export const DELETE_ACCOUNT_REQUEST = createRequestTypes('@user/DELETE_ACCOUNT_REQUEST')

export const constants = {
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  ADD_SYSTEM_TO_USER,
  DELETE_SYSTEM_FROM_USER,
  CONFIRM_USER_NEWS,
  DELETE_ACCOUNT,

  UPDATE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  ADD_SYSTEM_TO_USER_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  CONFIRM_USER_NEWS_REQUEST,
  DELETE_ACCOUNT_REQUEST
}

export const addSystemToUser = createAction(ADD_SYSTEM_TO_USER)
export const deleteSystemFromUser = createAction(DELETE_SYSTEM_FROM_USER)
export const updateUser = createAction(UPDATE_USER)
export const updateUserPassword = createAction(UPDATE_USER_PASSWORD)
export const confirmUserNews = createAction(CONFIRM_USER_NEWS)
export const deleteAccount = createAction(DELETE_ACCOUNT)

export const addSystemToUserRequest = createRequestAction(ADD_SYSTEM_TO_USER_REQUEST)
export const deleteSystemFromUserRequest = createRequestAction(DELETE_SYSTEM_FROM_USER_REQUEST)
export const updateUserRequest = createRequestAction(UPDATE_USER_REQUEST)
export const updateUserPasswordRequest = createRequestAction(UPDATE_USER_PASSWORD_REQUEST)
export const confirmUserNewsRequest = createRequestAction(CONFIRM_USER_NEWS_REQUEST)
export const deleteAccountRequest = createRequestAction(DELETE_ACCOUNT_REQUEST)

export default {
  updateUser,
  updateUserPassword,
  addSystemToUser,
  deleteSystemFromUser,
  confirmUserNews,
  deleteAccount,

  updateUserRequest,
  updateUserPasswordRequest,
  addSystemToUserRequest,
  deleteSystemFromUserRequest,
  confirmUserNewsRequest,
  deleteAccountRequest
}
