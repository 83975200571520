import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import actions, { constants } from 'common/actions/user'
import snackbarActions from 'common/actions/snackbar'
import modalActions from 'common/actions/modal'
import { getUserId } from 'common/selectors/user'
import systemsActions from 'common/actions/systems'

import * as api from 'api/user'
import * as schemas from 'schemas'
import * as Routes from 'constants/Routes'
import { browserHistory } from 'react-router'
import { clearTokenHeader } from 'utils/request'
import { clearAuthStoreData } from 'utils/authStore'

export function* onUpdateUser({ payload: data }) {
  yield put(actions.updateUserRequest.start())

  try {
    const { user } = yield call(api.updateUser, data)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.updateUserRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateUserRequest.failure(err))
  }
}

export function* onUpdateUserPassword({ payload: data }) {
  yield put(actions.updateUserPasswordRequest.start())

  try {
    const { user } = yield call(api.updateUserPassword, data)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.updateUserPasswordRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
  } catch (err) {
    yield put(actions.updateUserPasswordRequest.failure(err))
  }
}

export function* onUpdateCognitoUserPassword({ payload: data }) {
  let redirect
  redirect = route => {
    browserHistory.push(route)
  }

  yield put(actions.updateUserPasswordRequest.start())

  try {
    const result = yield call(api.updateCognitoUserPassword, data)
    if (result === 'SUCCESS') {
      yield put(snackbarActions.showSnackbar('snackbar.savedSuccessful'))
      yield put(redirect(Routes.HOME))
    } else {
      yield put(actions.updateUserPasswordRequest.failure(result))
    }
  } catch (err) {
    yield put(actions.updateUserPasswordRequest.failure(err))
  }
}

export function* onAddToUserSystem({ payload: data }) {
  yield put(actions.addSystemToUserRequest.start())

  try {
    const userId = yield select(getUserId)
    const { system } = yield call(api.addSystemToUser, userId, data)
    const norm = yield call(normalize, system, schemas.system)
    yield put(actions.addSystemToUserRequest.success(norm))
    yield put(snackbarActions.showSnackbar('snackbar.addedSuccessful'))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.addSystemToUserRequest.failure(err))
  }
}
export function* onDeleteSystemFromUser({ payload: systemId }) {
  yield put(actions.deleteSystemFromUserRequest.start())

  try {
    const userId = yield select(getUserId)
    yield call(api.deleteSystemFromUser, userId, systemId)

    yield put(actions.deleteSystemFromUserRequest.success({ userId, systemId }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
  } catch (err) {
    yield put(actions.deleteSystemFromUserRequest.failure(err))
  }
}

export function* onConfirmUserNews({ payload: data }) {
  yield put(actions.confirmUserNewsRequest.start())
  try {
    const userId = yield select(getUserId)
    const { user } = yield call(api.confirmUserNews, userId)
    const norm = yield call(normalize, user, schemas.user)

    yield put(actions.confirmUserNewsRequest.success(norm))
    yield put(modalActions.hideModal())
  } catch (err) {
    yield put(actions.confirmUserNewsRequest.failure(err))
  }
}

export function* onDeleteAccount({ payload: data }) {
  yield put(actions.deleteAccountRequest.start())
  try {
    yield call(api.deleteAccount, data._id, data)
    yield put(actions.deleteAccountRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('snackbar.deleteSuccessful'))
    yield put(systemsActions.pollSystemsStop())
    yield call(clearAuthStoreData)
    yield call(clearTokenHeader)
    browserHistory.push(Routes.LOGIN)
  } catch (err) {
    yield put(actions.deleteAccountRequest.failure(err))
    yield put(snackbarActions.showSnackbar('snackbar.whoops'))
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(constants.UPDATE_USER, onUpdateUser),
    takeLatest(constants.UPDATE_USER_PASSWORD, onUpdateCognitoUserPassword),
    takeLatest(constants.ADD_SYSTEM_TO_USER, onAddToUserSystem),
    takeLatest(constants.DELETE_SYSTEM_FROM_USER, onDeleteSystemFromUser),
    takeLatest(constants.CONFIRM_USER_NEWS, onConfirmUserNews),
    takeLatest(constants.DELETE_ACCOUNT, onDeleteAccount)
  ])
}
