import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware, { END } from 'redux-saga'
import makeRootReducer from 'common/reducers'
import loaderMiddleware from 'utils/loaderMiddleware'
import snackbarMiddleware from 'utils/snackbarMiddleware'

export default function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware()

  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
    loaderMiddleware,
    snackbarMiddleware
  ]

  const enhancers = []

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  const store = createStore(
    makeRootReducer(),
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  )

  store.asyncReducers = {}
  store.asyncSagas = {}
  store.sagaMiddleware = sagaMiddleware

  if (module.hot) {
    module.hot.accept('common/reducers', async () => {
      const reducers = await import('common/reducers/index')
      store.replaceReducer(reducers.default(store.asyncReducers))
    })

    module.hot.accept('common/sagas', async () => {
      const sagas = await import('common/sagas/index')
      sagaMiddleware.run(sagas.default(store.asyncSagas))
    })
  }

  store.runSaga = sagaMiddleware.run
  store.close = () => store.dispatch(END)

  return store
}
