import AppLayout from 'common/components/layouts/AppLayout'
import AuthLayout from 'common/components/layouts/AuthLayout'
import MainLayout from 'common/components/layouts/MainLayout'
import PublicLayout from 'common/components/layouts/PublicLayout'

import Login from './Login'
import Home from './Home'
import SignUp from './SignUp'
import ConfirmUserInfo from './ConfirmUserInfo'
import ConfirmUser from './ConfirmUser'
import ForgottenPassword from './ForgottenPassword'
import RecoverPassword from './RecoverPassword'
import Logout from 'modules/Logout'
import Dashboard from './Dashboard'
import ControlPanel from './ControlPanel'
import MoreInformation from './MoreInformation'

import Profile from './Profile'
import NotFound from './NotFound'

import requireAuth from 'utils/requireAuth'

import * as Routes from 'constants/Routes'

export default store => {
  return {
    component: AppLayout,
    childRoutes: [
      {
        component: AuthLayout,
        childRoutes: [
          Login(store),
          SignUp(store),
          ConfirmUserInfo(store),
          ConfirmUser(store),
          ForgottenPassword(store),
          RecoverPassword(store)
        ]
      },
      {
        component: requireAuth(MainLayout),
        path: Routes.MAIN,
        childRoutes: [
          Home(store),
          Dashboard(store),
          ControlPanel(store),
          Profile(store),
          MoreInformation(store)
        ]
      },
      {
        component: PublicLayout,
        childRoutes: [MoreInformation(store)]
      },
      Logout(store),
      NotFound(store),
      {
        path: '*',
        indexRoute: {
          onEnter: (nextState, replace) => replace(Routes.NOT_FOUND)
        }
      }
    ]
  }
}
